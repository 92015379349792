<div class="outer-div" *ngIf="ProductData">
  <div class="inner-div">
    <section class="image-section">
      <section class="full-image">
        <img (error)="updateUrl($event)" [src]="imageShown" />
      </section>
      <section class="imagesList">
        <img
          (mouseover)="changeImage(image)"
          *ngFor="let image of ProductData.products_images; let index = index"
          (error)="updateArrayUrl($event, index)"
          [src]="image"
        />
      </section>
    </section>
    <section class="text-section">
      <h1>{{ ProductData.product_name }}</h1>
      <h3>{{ ProductData.tag_line }}</h3>
      <div class="reviews">
        <i
          class="fas fa-star"
          *ngFor="
            let star of [].constructor(
              averageReviewCalculator(ProductData?.reviews)
            )
          "
        ></i>
        <i
          class="far fa-star"
          *ngFor="
            let star of [].constructor(
              5 - averageReviewCalculator(ProductData?.reviews)
            )
          "
        ></i>
      </div>
      <h4>
        <span class="price">₹ {{ ProductData.price }} </span>&nbsp;
        <span class="fake_price">₹ {{ ProductData.fake_price }} </span>
      </h4>

      <h4>
        {{ ProductData.product_purchasable ? "In Stock" : "Out of Stock" }}
      </h4>
      <div class="set-quantity">
        <div *ngIf="productType === 'single'">
          <p>Product Type : {{ ProductData.product_type | titlecase }}</p>
        </div>
        <p>Quantity</p>
        <section *ngIf="ProductData.addToCart" class="cart-section">
          <section (click)="removeQuantity()" class="remove-section">
            <mat-icon>remove</mat-icon>
          </section>
          <section class="quantity-section">
            <span style="padding: 2%">{{ ProductData.quantity }}</span>
          </section>
          <section (click)="addToCart()" class="add-section">
            <mat-icon>add</mat-icon>
          </section>
        </section>
        <button
          *ngIf="!ProductData.addToCart"
          mat-raised-button
          color="primary"
          (click)="addToCart()"
        >
          Add To Cart</button
        ><br />
      </div>
      <hr style="width: 100%" />
      <div class="icons">
        <div class="icon-details">
          <mat-icon class="icon" color="primary">verified</mat-icon>
          <section>Quality & Verified Product</section>
        </div>
        <div class="icon-details">
          <mat-icon class="icon" color="primary">local_shipping</mat-icon>
          <section>Fast Delivery</section>
        </div>
        <div class="icon-details">
          <mat-icon class="icon" color="primary">currency_exchange</mat-icon>
          <section>Refundable</section>
        </div>
        <div class="icon-details">
          <mat-icon class="icon" color="primary">free_cancellation</mat-icon>
          <section>Cancellation Available</section>
        </div>
        <div class="icon-details">
          <mat-icon class="icon" color="primary">location_on</mat-icon>
          <section>All Over India Delivery</section>
        </div>
      </div>
      <hr style="width: 100%" />
      <i>Transaction Fee will be charged at the time of order cancellation</i
      ><br />
      <h4>Description</h4>
      <p [innerHTML]="ProductData.product_description"></p>
      <div *ngIf="productType === 'single'">
        <h4>Indication</h4>
        <ul class="lato-font">
          <li *ngFor="let indicate of ProductData.indication">
            {{ indicate }}
          </li>
        </ul>
      </div>
      <div *ngIf="productType === 'combo'" style="width: 100%">
        <h4>Products</h4>
        <div class="heading-sections">
          <table class="heading-inner">
            <tr style="width: 100%">
              <th class="left-column">Product Name</th>
              <th style="width: 50%">Product Type</th>
            </tr>
            <tr
              style="width: 100%"
              *ngFor="let product of ProductData.products"
            >
              <td class="left-column">
                {{ product.product_name }}
              </td>
              <td style="width: 50%">{{ product.product_type }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div *ngIf="productType === 'single'" style="width: 100%">
        <h4>Composition</h4>
        <div class="heading-sections">
          <table class="heading-inner">
            <tr style="width: 100%">
              <th class="left-column merriweather-font">Ingredient</th>
              <th class="merriweather-font" style="width: 50%">Quantity</th>
            </tr>
            <tr
              style="width: 100%"
              *ngFor="let composition of ProductData.composition"
            >
              <td class="left-column lato-font">
                {{ composition.name }}
              </td>
              <td class="lato-font" style="width: 50%">
                {{ composition.quantity }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  </div>
  <div class="review" *ngIf="!interviewAdded">
    <app-add-review></app-add-review>
  </div>
  <div *ngIf="ProductData.reviews.length > 0" style="width: 100%">
    <app-reviews
      [reviews]="ProductData.reviews"
      style="width: 100%"
    ></app-reviews>
  </div>
</div>
